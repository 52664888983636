<template>
  <div>
    <v-card flat class="pa-1" v-if="['project', 'equipment'].includes(module)">
      <v-card-text class="pa-0">
        <h3 v-if="module === 'project'">{{$t('message.projects.project')}}: <router-link v-if="propId" :to="`/projects/${$route.params[propId]}`">{{ printProjectName }}</router-link> <router-link  v-else :to="`/projects/${globalListSelectedProjectId}`">{{ printProjectName }}</router-link></h3>
        <h3 v-if="module === 'equipment'">{{printEquipmentName}}</h3>
      </v-card-text>
    </v-card>
    <v-row class="mx-0 mt-2 mb-1">
      <v-col cols="8" class="pa-0 px-1">
        <!-- <v-select :disabled="checklistTemplatesLoading" :loading="checklistTemplatesLoading" :items="getSelectedChecklists" @change="goToChecklistForm" item-text="title" item-value="id" v-model="getChecklist" filled :label="$t('message.layout.checklists')" dense hide-details></v-select> -->
        <v-autocomplete chips color="blue-grey lighten-2" :disabled="checklistTemplatesLoading" :loading="checklistTemplatesLoading"
          :items="getSelectedChecklists" @change="goToChecklistForm" item-text="title" item-value="id" v-model="getChecklist"
          filled :label="$t('message.layout.checklists')" dense hide-details class="test-class"></v-autocomplete>
      </v-col>
      <v-col cols="4" class="pa-0 px-1">
        <v-select v-model="checklistStatus" :items="checklistStatusList" :item-text="(val) => $t(val.text)" item-value="value" filled dense hide-details @change="getListHandler(null)" :label="$t('message.projects.status')"></v-select>
      </v-col>
    </v-row>
    <compact-list :payload="compactListPayload"></compact-list>
    <!-- status change dialog -->
    <v-dialog v-model="statusChangeDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('message.common.confirmStatusChange') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('message.common.areYouSureToChangeStatus', { statusText: activeItemForStatusChange && activeItemForStatusChange.is_completed ? $t('message.common.incomplete') : $t('message.common.complete') }) }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="statusChangeConfirm" :loading="statusChangeLoading" @click="confirmChangeStatus">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="warning" id="statusChangeClose" @click="statusChangeDialog=false, activeItemForStatusChange=null">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Import CSV Dialog- test -->
    <v-dialog v-model="importCsvDialog" width="650" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">{{ $t('message.checklist.importCSV') }}
        </v-card-title>
        <v-card-text class="mt-4">
          <span v-html="$t('message.checklist.CSVFileInfo')"  class="h4 font-weight-bold"> </span>
          <ul v-for="(item, index) in csvInformations" :key="index">
            <li class="mt-1">{{ item.text }}</li>
          </ul>
          <v-divider class="my-3"></v-divider>
          <v-file-input v-model="csvFile" prepend-icon="" hide-details prepend-inner-icon="mdi-file-delimited" :label="$t('message.checklist.addCSVFile')" accept=".csv" outlined dense></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="readCSVFileAndSetData()">{{ $t('message.common.import') }}</v-btn>
          <v-btn color="primary" @click="withoutImport()">{{ $t('message.checklist.withoutImport') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" class="white--text-custom" @click="importCsvDialog = false,  csvFile=null, getChecklist=0">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CompactList from '@/components/checklist/CompactList'
import helpers from '../../utils/actions'
import { checklistApi, hostAppApi } from '../../plugins/axios_settings'
import projectMixin from '@/mixins/projectMixin'
import { fieldTypeConstants } from '@/utils/support_list'
export default {
  mixins: [projectMixin],
  props: ['module', 'model', 'payload', 'moduleTitle', 'propId', 'projectId'],
  data () {
    return {
      getChecklist: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.PROPS_INDEX) ? this.$store.state.common.PROPS_INDEX : { itemsPerPage: 8, page: 1 },
      search: '',
      getSelectedChecklists: [],
      moduleName: 'checklist',
      /* pwa */
      compactListPayload: {
        hideHeader: false,
        editHandler: this.updateChecklistDialog,
        refreshHandler: this.getListHandler,
        downloadHandler: (item) => this.downloadChecklist(item.checklist_api_id, item.checklist_template_api_id, item.title, item.checklist_name, false, item.id),
        sendChecklistReport: (id, formId, formName, checklilstName) => this.sendChecklistReport(id, formId, formName, checklilstName),
        pdfTemplateReportDownload: (item) => this.pdfTemplateReportDownload(item),
        changeStatus: this.changeChecklistStatus,
        moduleTitle: '',
        hasNoNext: false,
        loading: false,
        list: [],
        pagination: {},
        storeIndex: 'PROPS_INDEX',
        items: [{ value: 'title' }, { value: 'checklist_name' }],
        hasFilter: false,
        isCustomModule: false, // flag to show the relate unrelate options
        isChecklist: true,
        moduleIcon: 'mdi-book-plus',
        hasPermission: true,
        backTo: this.backTo
      },
      deleteSuccessPagination: null,
      hostRef: this.$cookie.get('AxentivPwa_host-ref'),
      checklistDeleteId: '',
      statusChangeLoading: false,
      statusChangeDialog: false,
      activeItemForStatusChange: null,
      equipmentObj: null,
      checklistStatusList: [
        { text: 'message.projects.all', value: 3 },
        { text: 'message.customModule.completed', value: 1 },
        { text: 'message.customModule.notCompleted', value: 2 }
      ],
      checklistStatus: 2,
      checklistTemplatesLoading: false,
      globalListSelectedProjectId: 0,
      importCsvDialog: false,
      csvFile: null,
      csvInformations: [
        { text: this.$t('message.checklist.removeRow') },
        { text: this.$t('message.checklist.sameOrederColumn') },
        { text: this.$t('message.checklist.semicolonDelimiter') }
      ],
      datagridFormProperties: null,
      selectedChecklistTemplate: null,
      dataGridImportValue: null,
      fieldTypeConstants: fieldTypeConstants
    }
  },
  components: {
    'compact-list': CompactList
  },
  computed: {
    ...mapGetters(['getChecklistForms', 'listOfModules', 'userDetails', 'getHostRefApi', 'userId']),
    printEquipmentName () {
      if (this.equipmentObj) return this.setEquimentName(this.equipmentObj)
      else return ''
    }
  },
  created () {
    this.getListOfChecklists()
  },
  mounted () {
    this.compactListPayload.moduleTitle = `${this.moduleTitle}`
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.moduleName) {
        this.deleteSuccessPagination = data.pagination
        this.checklistDeleteId = data.checklistId
        const payload = { url: `checklist/${data.id}`, isSingle: true, module: this.moduleName, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.moduleName) {
        this.pagination = this.deleteSuccessPagination
        this.deleteChecklistItem()
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.deleteSuccess' })
        this.$store.commit('hideDialog')
        this.getListHandler()
      }
    })
    this.$eventBus.$on('clearChecklist', (projectId) => {
      this.getChecklist = null
      this.getListHandler(projectId || null)
    })
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
    this.getListHandler()
  },
  methods: {
    downloadChecklist (checklistId, formId, formName, checklilstName = '', isEmailReport = false, _id, projectdata = '') {
    // downloadChecklist (checklistId, formId, formName) {
      this.compactListPayload.loading = true
      this.$eventBus.$emit('showLoading')
      hostAppApi.get(`${this.getHostRefApi}get-organization-logo?user_id=${this.userId}`)
        .then((res) => {
          // helpers.downloadReportHelper(checklistId, formId, formName, this.$cookie.get(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN), res.data || '', isEmailReport, projectdata, allTransData)
          const allTransData = { attachmentsBelow: this.$t('message.mixed.attachmentsBelow') }
          helpers.downloadReportHelper(checklistId, formId, formName, this.$cookie.get(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN), res.data || '', checklilstName, isEmailReport, projectdata, allTransData)
            .then(() => {
              if (isEmailReport) {
                const checklistReport = localStorage.getItem('checklist_report_' + checklistId)
                hostAppApi.get(this.getHostRefApi + `checklist-send-reprot/${checklistId}?checklist_report_file=${checklistReport}`)
                  .then((response) => {
                    const text = response.data.message
                    if (response.data.status === 'success') {
                      this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
                    } else {
                      this.$root.$emit('snackbar', { snackbar: true, color: 'warning', text })
                    }
                  })
                  .catch((error) => {
                    this.$handleServerSideError(error)
                  })
              }
              this.$eventBus.$emit('hideLoading')
              if (isEmailReport) {
                const checklistReport = window.localStorage.getItem(`checklist_report_${checklistId}`)
                const url = `https://${this.hostRef}/api/checklist-send-reprot/${checklistId}?checklist_report_file=${checklistReport}`
                hostAppApi.get(url).then((response) => {
                  const text = response.data.message
                  if (response.data.status === 'success') {
                    this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
                  } else {
                    this.$root.$emit('snackbar', { snackbar: true, color: 'warning', text })
                  }
                }).catch((error) => {
                  this.$handleServerSideError(error)
                })
              }
            })
            .finally(() => { this.compactListPayload.loading = false })
        })
    },
    getListOfChecklists (projectId = null, isFromGlobalProject) {
      this.checklistTemplatesLoading = true
      if (isFromGlobalProject) this.globalListSelectedProjectId = projectId
      projectId = projectId || this.$route.params[this.propId]
      if (this.$route.path === '/global_checklist') projectId = projectId || this.globalListSelectedProjectId
      let url = `${this.getHostRefApi}get_checklist_templates?status=2`
      switch (this.module) {
        case 'project':
          url += `&api_project_id=${projectId}`
          this.getProject(parseInt(projectId))
          break
        case 'equipment':
          url += `&object_type=6&api_object_id=${this.$route.params[this.propId]}`
          this.getEquipment(parseInt(this.$route.params[this.propId]))
          break
        default:
          break
      }
      hostAppApi.get(url).then((response) => {
        if (response && response.data) {
          this.getSelectedChecklists = response.data
        }
        this.checklistTemplatesLoading = false
      })
    },
    getListHandler (projectId = null, isFromGlobalProject) {
      if (isFromGlobalProject) this.globalListSelectedProjectId = projectId
      projectId = projectId || this.$route.params[this.propId]
      if (this.$route.path === '/global_checklist') projectId = projectId || this.globalListSelectedProjectId
      let url = `${this.getHostRefApi}checklist?object_type=`
      switch (this.module) {
        case 'global':
          url += '1'
          break
        case 'project':
          url += `2&api_project_id=${projectId}`
          break
        case 'equipment':
          url += `6&api_object_id=${this.$route.params[this.propId]}`
          break
        default:
          break
      }
      url += `&status=${this.checklistStatus || 3}`
      this.compactListPayload.loading = true
      this.compactListPayload.module = this.moduleName
      hostAppApi.get(url).then((response) => {
        if (response && response.data) {
          this.compactListPayload.list = response.data
        }
      }).finally(() => {
        this.compactListPayload.loading = false
      })
    },
    goToChecklistForm () {
      this.checklistTemplateChange()
      // const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
      // if (getForm) {
      //   const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
      //   this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email })
      // }
      return ''
    },
    updateChecklistDialog (item) {
      this.$eventBus.$emit('updatechecklistDialog', { checklist_id: item.checklist_api_id, itemId: item.checklist_template_id, form_id: item.checklist_template_api_id, form_name: item.title, title: item.checklist_name || '', checklist_recipients: item.checklist_recipients || '', can_send_email: item.can_send_email })
    },
    deleteChecklistItem () {
      checklistApi.delete(`/checklists/${this.checklistDeleteId}`)
        .then((response) => {
        }).finally(() => {
        })
    },
    changeChecklistStatus (item) {
      this.activeItemForStatusChange = item
      this.statusChangeDialog = true
    },
    confirmChangeStatus () {
      this.statusChangeLoading = true
      const isCompleted = this.activeItemForStatusChange.is_completed === 0 ? 1 : 0
      const model = { checklist_template_id: this.activeItemForStatusChange.checklist_template_id, is_completed: isCompleted }
      hostAppApi.put(`${this.getHostRefApi}checklist/${this.activeItemForStatusChange.checklist_api_id}`, model)
        .then((response) => {
          this.getListHandler()
        }).finally(() => {
          this.statusChangeLoading = false
          this.statusChangeDialog = false
          this.activeItemForStatusChange = null
        })
    },
    backTo () {
      let url = ''
      switch (this.module) {
        case 'global':
          url = '/dashboard'
          break
        case 'project':
          url = `/projects/${this.$route.params[this.propId]}`
          break
        case 'equipment':
          url = `/equipments/${this.$route.params[this.propId]}`
          break
        default:
          break
      }
      this.$router.push(url)
    },
    getEquipment (id) { // equipment id
      hostAppApi.get(`${this.getHostRefApi}equipment/${id}/edit`).then((response) => {
        if (response && response.data) this.equipmentObj = this.$formatter.cloneVariable(response.data)
      })
    },
    sendChecklistReport (id, formId, formName, checklilstName = '', projectdata = '') {
      // const url = `https://${this.hostRef}/api/checklist-send-reprot/${item.checklist_api_id}`
      localStorage.setItem('checklist_report_' + id, false)
      this.downloadChecklist(id, formId, formName, checklilstName, true)
      // const url = `https://${this.getHostRefApi}checklist-send-reprot/${item.checklist_api_id}`
      // hostAppApi.get(url).then((response) => {
      //   const text = response.data.message

      //   if (response.data.status === 'success') {
      //     this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
      //   } else {
      //     this.$root.$emit('snackbar', { snackbar: true, color: 'warning', text })
      //   }
      // }).catch((error) => {
      //   this.$handleServerSideError(error)
      // })
      // window.localStorage.setItem(`checklist_report_${id}`, false)
      // this.downloadChecklist(id, formId, formName, checklilstName, true, projectdata)
    },
    checklistTemplateChange () {
      const cprojectId = ''
      hostAppApi.get(`https://${this.hostRef}/api/checklist-template-builder/${this.getChecklist}/edit?project_id=${cprojectId}`)
        .then((response) => {
          if (response.data.status !== 'error' && response.data.status !== 'danger') {
            this.selectedChecklistTemplate = response.data
            if (+this.selectedChecklistTemplate.checklist_type_id === 2) this.importCsvDialog = true
            this.checklistProjectData = response.data.formdata ? response.data.formdata.data : []
            const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
            if (getForm && !this.importCsvDialog) {
              const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
              this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email, projectId: this.projectId, getChecklist: this.getChecklist })
            }
          }
        })
        .catch((error) => {
          this.$handleServerSideError(error)
        })
    },
    readCSVFileAndSetData () {
      var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/
      if (regex.test(this.csvFile.name.toLowerCase())) {
        if (typeof (FileReader) !== 'undefined') {
          this.importCsvDialog = false
          /* get the form properties of checklist */
          this.getDataGridFormProperties()
          this.saveWithoutImport = false
        } else alert('This browser does not support HTML5.')
      } else {
        alert('Please upload a valid CSV file.')
      }
    },
    getDataGridFormProperties () {
      if (this.selectedChecklistTemplate) {
        checklistApi
          .get(`formproperties/get_by_form/${this.selectedChecklistTemplate.checklist_template_api_id}`)
          .then((response) => {
            if (response && response.data) this.datagridFormProperties = response.data.filter(x => x.type === 18) // get only datagrids
            const model = {
              title: this.ckTitle,
              data: {},
              form_id: this.selectedChecklistTemplate.checklist_template_api_id
            }
            model.data.values = []
            /* If DataGrid & type Import & has csvfile */
            var getDataFromCSV = new Promise((resolve, reject) => this.setDataFromCSV(resolve, reject))
            getDataFromCSV.then((addedRows) => {
              model.data = { ...model.data, ...addedRows }
              this.dataGridImportValue = model
              const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
              if (getForm) {
                const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
                this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email, projectId: this.projectId, getChecklist: this.getChecklist, dataGridImportValue: this.dataGridImportValue })
              }
            }).catch((e) => {})
          })
      }
    },
    setDataFromCSV (resolve, reject) {
      const datagridFormProperties = this.datagridFormProperties || []
      const data = {}
      var self = this
      try {
        var reader = new FileReader()
        reader.onload = function (e) {
          var rows = e.target.result.split('\n')
          datagridFormProperties.forEach(gd => { // gd-grid
            data[gd.name] = {}
            const defaultValue = gd.default_value ? JSON.parse(gd.default_value) : null
            const properties = defaultValue.grid_data.labels
            let resultGrid = {}
            rows.forEach((row, index) => {
              if (!row) return
              row = row.replace('\r', '')
              var separator = ','
              if (row.includes(';')) separator = ';'
              const gridData = []
              var cells = row.split(separator)
              for (let i = 0; i < +gd.label; i++) {
                let value = cells[i] || ''
                if (properties[i].field.type === self.fieldTypeConstants.CHECKBOX) value = value ? [value] : []
                gridData.push({ value, isShow: true })
              }
              resultGrid = Object.assign({ ...resultGrid }, { [`row_${index}`]: [...gridData] })
            })
            data[gd.name] = resultGrid
          })
          resolve(data)
        }
        reader.readAsText(this.csvFile)
      } catch (ex) { reject(ex) }
    },
    withoutImport () {
      this.importCsvDialog = false
      this.csvFile = null
      const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
      if (getForm) {
        const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
        this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email, projectId: this.projectId, getChecklist: this.getChecklist })
      }
    },
    pdfTemplateReportDownload (item) {
      this.compactListPayload.loading = true
      hostAppApi.get(`${this.getHostRefApi}checklist/${item.id}/report?user_id=${this.userId}`)
        .then((res) => {
          if (res && res.data.message) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: res.data.message, isException: true })
          if (res && res.data && res.data.file) window.open(res.data.file, '_blank')
        })
        .finally(() => { this.compactListPayload.loading = false })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('clearChecklist')
    this.$eventBus.$off('loadList')
  }
}
</script>
